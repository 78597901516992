<template>
  <transition name="pdf-fade">
    <div class="pdfmodal">
      <CRow>
        <CCol class="text-right">
          <CButton @click="onClose"  color="danger" class="mb-2">
            закрыть
          </CButton>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="7">
          <CRow>
              <h5>Поверка № {{data.number}} от {{data.created_at1}} г. </h5>
              <br/>
              <table class="table table-striped small">
                <tr>
                  <td rowspan="9" valign="bottom">
                    <a :href="srcJpgMeter" target="_blank">
                      <img
                          class="meter" :src="srcJpgMeter"/>
                    </a>
                  </td>
                  <td width="60%" class="text-left">
                    <ul>
                      <li>
                        Модификация: {{ data.siType }}
                      </li>
                      <li>
                        Заводской номер: {{ data.serialNumber }}
                      </li>
                      <li>
                        Дата поверки: {{ data.date }}
                      </li>
                      <li>
                        Действует до: {{ data.nextTest }}
                      </li>
                      <li>
                        Номер в Госреестре: {{ data.regNumber }}
                      </li>
                      <li>
                        Методика поверки: {{ data.checkMethod }}
                      </li>
                      <li>
                        Вода: {{ data.waterType }}
                      </li>
                      <li>
                        Помещение: {{ data.place }}
                      </li>
                      <li v-if="data.exported">
                        <a :href="`https://fgis.gost.ru/fundmetrology/cm/results?filter_mi_number=${data.serialNumber}&filter_org_title=МС-РЕСУРС&activeYear=${new Date(Date.parse(data.date)).getFullYear()}`" target="_blank">
                          Проверить в ФИФ
                        </a> (в электронном реестре)
                      </li>

                    </ul>
                  </td>
                </tr>
              </table>
            </CRow>
          </CCol>
        <CCol sm="5" v-if="Object.keys(data).length">
          <div v-if="data.date.slice(0, 7).split('-')[0] <= 2020">
            <carousel
              :per-page="1"
              :paginationEnabled="true"
              :mouse-drag="true"
              :autoplay="true">
              <slide>
                <img :src="srcOldJpg"/>
              </slide>
              <slide>
                <img :src="srcOldJpg1"/>
              </slide>
            </carousel>
          </div>
          <img :src="srcJpg" v-else/>
        </CCol>
      </CRow>
    </div>
  </transition>
</template>

<script>

import { mapGetters } from 'vuex';
import {ADDRESS} from "../../backserver";

export default {
  name: "MeterModal",
  props: {
    data: {
      type: Object,
      required: true
    },
    contract: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      changeStatus: false,
      numPages: 4,
      currentPage: 0,
      pageCount: 0,
      type_show: false
    }
  },
  computed: {
    ...mapGetters([
      'getProfile',
    ]),
    srcJpg() {
      if (this.data.created_at1) {
        let date = this.data.created_at1;
        date = date.slice(0, 7);
        date = date.replace('-', '/');

          let str = 'act_' + this.data.name + '.jpg';
          str = str.replace('photos/', '');
          str = '/photo/' + date + '/' + str;
          return `${ADDRESS}${str}`;
      }
      else
        return null;
    },
    srcOldJpg(val) {
      // console.log('data', this.data);
      if (this.data.date) {
        let str = this.data.name + '.jpg';
        let date = this.data.date;
        date = date.slice(0, 7);
        date = date.replace('-', '/');
        str = str.replace('photos/', '');
        str = '/old-photo/' + date + '/' + str;
        return `${ADDRESS}${str}`;
      }
      else
        return '';
    },
    srcOldJpg1(val) {
      // console.log('data', this.data);
      if (this.data.date) {
        let str = this.data.name + '.jpg';
        let date = this.data.date;
        date = date.slice(0, 7);
        date = date.replace('-', '/');
        str = str.replace('photos/', '');
        str = '/old-photo1/' + date + '/' + str;
        return `${ADDRESS}${str}`;
      }
      else
        return '';
    },
    srcJpgMeter() {
      // console.log('data', this.data);
      if (this.data.date) {
        let str = this.data.meter_photo;
        let date = this.data.created_at1;
        date = date.slice(0, 7);
        date = date.replace('-', '/');
        str = str.replace('photos/', '');
        str = '/photo/' + date + '/' + str;
        return `${ADDRESS}${str}`;
      }
      else
        return null;
    }
  },
  methods: {
    onClose() {
      document.body.classList.remove('noscroll');
      // alert('asdasd');
      this.$emit('hide-document');
      this.changeStatus = false;
    },
    changePage(step)
    {
      this.changeStatus = false;
      this.$emit('change-document', step, this.data.current, this.data.documents)
    },
    showMeters() {
      this.type_show = !this.type_show;

    }
  }
}
</script>

<style scoped>

.noscroll{
  overflow: hidden !important;
}
.pdfmodal {
  position: fixed;
  z-index: 10000;
  background-color: #ffffff;
  top: 0;
  right: 0;
  height: 100vh;
  width: 90vw;
  padding: 30px 30px 30px 20px;
  -webkit-box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
}
.pdfmodal .close {
  font-size: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 100;
}
.pdf-fade-enter-active, .pdf-fade-leave-active {
  transition: right 0.5s linear;
  right: 0;
}
.pdf-fade-enter, .pdf-fade-leave-to  {
  right: -100%;
}


body {
  margin: 0;
  padding: 0;
  background-color: #606f7b;
}
img {
  max-height: 100vh;
}
img.old {
  max-width: 50%;
}
img.meter {
  max-height: 50vh;
}
label.form {
  color: white;
  font-family: Monaco, 'Courier New', Courier, monospace;
  font-weight: bold;
  margin-bottom: 2em;
  display: block;
}
input {
  padding: 0.45em;
  font-size: 1em;
}
.error {
  border: 1px solid red;
  background: pink;
  color: red;
  padding: 0.5em 3em;
  display: inline;
}
a.icon {
  cursor: pointer;
  display: block;
  border: 1px #333 solid;
  background: white;
  color: #333;
  font-weight: bold;
  padding: 0.25em;
  width: 1em;
  height: 1em;
  font-size: 1.5em;
}
.box-shadow {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
.overflow-hidden {
  overflow: hidden;
}
@media print {
  body {
    background-color: transparent;
  }
  #app {
    margin: 0;
    padding: 0;
  }
}
#meters-slider.carousel-control-prev, #meters-slider.carousel-control-next {
  color: #0b2e13;
}

ul {
  list-style: none;
}
li {
  padding: 3px;
}
img {
  width: 100%;
  /*height: 25vh;*/
}
</style>
