import axios from 'axios'
import store from '@/store'

export function PROCESS() {
  // При отправке любого запроса на сервер аксиосом показываем прогресс бар
  axios.interceptors.request.use(function (config) {
    store.dispatch('SET_PROCESS', 1);
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  // Когда получили ответ от сервера скрываем процесс бар
  axios.interceptors.response.use(function (response) {
    store.dispatch('SET_PROCESS', -1);
    return response;
  }, function (error) {
    store.dispatch('SET_PROCESS', -1);
    return Promise.reject(error);
  });
}
