import Vue from 'vue'
import auth from '@/store/module/auth'
import user from '@/store/module/user'
import menu from '@/store/module/menu'
import notification from '@/store/module/notification'
import process from "@/store/module/process";
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    notification,
    menu,
    process
  }
})
