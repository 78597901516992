import 'core-js/stable'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSets as icons } from '@/assets/icons/icons'
import store from '@/store'
// @ts-ignore
import axios from 'axios'
import { getCookie } from '@/helpers/cookie'
// @ts-ignore
import SideBarNavItem from '@/views/base/SideBarNavItem'

// @ts-ignore
import MeterModal from '@/views/modal/MeterModal';
// @ts-ignore
import PdfModal from "@/views/modal/PdfModal";
// @ts-ignore
import VueCarousel from 'vue-carousel';

// @ts-ignore
import ServiceRequestModal from "@/views/modal/ServiceRequestModal"


import PaymentModal from "@/views/modal/PaymentModal.vue";

import DadataSuggestions from 'vue-dadata-suggestions'

import PrimeVue from 'primevue/config';

Vue.use(DadataSuggestions,{
  token: '57ca0f1370b026c7df895e4b1b27a8c2bea7180f',
  type: 'ADDRESS'
});

import VueMask from 'v-mask'
Vue.use(VueMask);

import {PROCESS} from "@/api/process";

// @ts-ignore
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

Vue.use(VueCarousel);

// import VueGoodTablePlugin from 'vue-good-table';
// // import the styles
// import 'vue-good-table/dist/vue-good-table.css'
// Vue.use(VueGoodTablePlugin);

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

import 'primevue/resources/themes/aura-light-green/theme.css'

Vue.component('vue-good-table', VueGoodTable);
Vue.component('meter-modal', MeterModal);
Vue.component('pdf-modal', PdfModal);
Vue.component('service-request-modal', ServiceRequestModal);
Vue.component('payment-modal', PaymentModal);
Vue.component('SideBarNavItem', SideBarNavItem);

Vue.use(Vuex);

// @ts-ignore
// const icons = icons;

// Vue.use(icons);

/**
 * Авторизационный токен
 */
const token = getCookie('api_token')
if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`

import { ADDRESS } from './backserver';
axios.defaults.baseURL = `${ADDRESS}`;
// console.log('SET axios.defaults.baseURL and bearer token', axios.defaults.baseURL);

const requirePlugin = require.context(
  '@/plugins',
  false,
  /\w+\.(ts|js)$/
)
requirePlugin.keys().forEach((pluginName) => {
  const plugin = requirePlugin(pluginName)
  if (plugin.setup) {
    plugin.setup(Vue)
  }
})


Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

// Vue.use(PrimeVue)

// @ts-ignore
new Vue({
  el: '#app',
  router,
  store,
  data() {
    return {
      icons: icons
    }
  },
  components: {
    SideBarNavItem
  },
  created() {
    PROCESS();
  },
  render: (h) => h(App)
}).$mount('#app')

