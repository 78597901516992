import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import isUser from "./middleware/isUser";
import isDispatcher from "./middleware/isDispatcher";
import isResponsible from "./middleware/isResponsible"
import middlewarePipeline from './middlewarePipeline'
import pagePrivacy from "../views/pages/PagePrivacy.vue";

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Support = () => import('@/views/pages/Support')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const PageOffer = () => import('@/views/pages/PageOffer')
const PageInfo = () => import('@/views/pages/PageInfo')
const PagePrivacy = () => import('@/views/pages/PagePrivacy')
const Login = () => import('@/views/pages/Login')
const AutoLogin = () => import('@/views/pages/AutoLogin')
const AutoLoginAct = () => import('@/views/pages/AutoLoginAct')
const AutoLoginPage = () => import('@/views/pages/AutoLoginPage')
const Register = () => import('@/views/pages/Register')
const ForgotPassword = () => import('@/views/pages/ForgotPassword')
const RestorePassword = () => import('@/views/pages/RestorePassword')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

const Acts = () => import('@/views/pages/Acts')
const UserServiceRequests = () => import('@/views/pages/UserServiceRequests')
const AgentServiceRequests = () => import('@/views/pages/ServiceRequests/AgentServiceRequests')
const DispatcherServiceRequests = () => import('@/views/pages/DispatcherServiceRequests')
const Agents = () => import('@/views/pages/Agents')
const Meters = () => import('@/views/pages/Meters')
const Statistic = () => import('@/views/pages/Statistic')
const Profile = () => import('@/views/pages/Profile')
const Payment = () => import('@/views/pages/Payment')
const PaymentHistory = () => import('@/views/pages/PaymentHistory')
const PageNotFound = () => import('@/views/pages/Login')


Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: { title: 'Поверка дома. Поверка счетчиков воды', auth: true },
      children: [
        {
          path: 'user-service-requests',
          name: 'UserServiceRequests',
          component: UserServiceRequests,
          meta: { title: 'Заявки поверителей', auth: true,
          middleware: [
            isUser
          ]},
        },
        {
          path: 'agent-service-requests',
          name: 'AgentServiceRequests',
          component: AgentServiceRequests,
          meta: { title: 'Заявки созданные агентом', auth: true,
          middleware: [
            isResponsible
          ]},
          props: true
        },
        {
          path: 'dispatcher-service-requests',
          name: 'DispatcherServiceRequests',
          component: DispatcherServiceRequests,
          meta: { title: 'Заявки диспетчера', auth: true,
            middleware: [
              isDispatcher
            ]
          },
        },
        {
          path: 'agents',
          name: 'Agents',
          component: Agents,
          meta: { title: 'Агенты', auth: true,
            middleware: [
              isResponsible
            ]
          },
        },
        {
          path: 'acts',
          name: 'Acts',
          component: Acts,
          meta: { title: 'Акты', auth: true },
        },
        {
          path: 'meters',
          name: 'Meters',
          component: Meters,
          meta: { title: 'Поверки', auth: true },
        },
        {
          path: 'statistic',
          name: 'Statistic',
          component: Statistic,
          meta: { title: 'Статистика', auth: true },
        },
        {
          path: 'profile',
          name: 'Profile',
          component: Profile,
          meta: { title: 'Профиль поверителя', auth: true },
        },
        {
          path: 'payment',
          name: 'Payment',
          component: Payment,
          meta: { title: 'Оплата пакетов', auth: true},
        },
        {
          path: 'payment-history',
          name: 'PaymentHistory',
          component: PaymentHistory,
          meta: { title: 'Платежи', auth: true},
        },
        {
          path: 'payment/Home',
          name: 'PaymentHistory',
          component: PaymentHistory,
          meta: { title: 'Платежи', auth: true},
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'support',
          name: 'Support',
          component: Support
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/coreui-icons',
          name: 'CoreUI Icons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'Icons library',
              component: CoreUIIcons
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      meta: { title: 'Поверка дома. Поверка счетчиков воды' },
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'info',
          name: 'PageInfo',
          component: PageInfo
        },
        {
          path: 'offer',
          name: 'PageOffer',
          component: PageOffer
        },
        {
          path: 'privacy',
          name: 'PagePrivacy',
          component: PagePrivacy
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'auto-login-act/:id/:act/:uid',
          name: 'AutoLoginAct',
          component: AutoLoginAct
        },
        {
          path: 'auto-login/:id/:page',
          name: 'AutoLoginAct',
          component: AutoLoginPage
        },
        {
          path: 'auto-login/:id',
          name: 'AutoLogin',
          component: AutoLogin
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'forgot-password',
          name: 'ForgotPassword',
          component: ForgotPassword
        },
        {
          path: 'restore-password/:email/:token',
          name: 'RestorePassword',
          component: RestorePassword,
          props: true
        },
      ]
    },
    {
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
    meta: {
      title: 'Страница отсутствует'
    }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.auth)
  // console.log('middleware', to);

  if (requireAuth && !store.getters.isAuthenticated) {
    next('/pages/login')
  }
  else if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  if (middleware)
  if (middleware.length)
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    })
  else
    return next()
})

// router.beforeEach((to, from, next) => {
//   if (!to.meta.middleware) {
//     return next()
//   }
//   const middleware = to.meta.middleware
//   const context = {
//     to,
//     from,
//     next,
//     store
//   }
//   return middleware[0]({
//     ...context,
//     next: middlewarePipeline(context, middleware, 1)
//   })
// })

export default router
