<template>
  <transition name="pdf-fade">
    <div class="pdfmodal" :class="{isMobile}">
      <CRow>
        <CCol class="text-right">
          <CButton @click="onClose"  color="danger" class="mb-2">
            закрыть
          </CButton>
        </CCol>
      </CRow>
      <CForm @submit.prevent="saveData(transaction)">
      <CRow>
          <CCol sm="12">
            <CRow class="flex-fill">
              <h5>Платеж № {{transaction.id}} от {{transaction.date}}  *</h5>
              <br/>
             <table class="table table-striped small">
              <tr>
                <td>Назначение платежа:</td>
                <td>
                  {{ transaction.comment }}
                </td>
              </tr>
             </table>
              <table class="table">
              <tr>
                <th>
                  <form novalidate @submit.prevent="submitFile">
                  <input
                    type="file"
                    name="file"
                    @change="uploadFile($event)"
                    accept="image/*"
                    capture
                  />
<!--                  <input type="file" @change="uploadFile" />-->
                  <button type="submit">Отправить</button>
                  </form>
                </th>
              </tr>
              </table>
            </CRow>
          </CCol>
        </CRow>
      </CForm>
    </div>
  </transition>
</template>
<script>

import {computed, reactive, watch, ref, defineComponent} from '@vue/composition-api';
import VueSuggestions from 'vue-suggestions';
import axios from "axios";

export default {
  name: "PaymentModal",
  components: { VueSuggestions },
  props: {
    transaction: {
      type: Object,
      required: true
    },
  },
  setup( props, ctx) {

    const data = reactive({
      Images: {},
    });

    const isMobile = computed(() => {
      return window.innerWidth <= 760 ? true : false
    })

    const file = ref(null);
    const form = ref();

    const uploadFile = (event) => {
      const target = event.target;
      if (target && target.files) {
        file.value = target.files[0];
      }
    }

    const submitFile = async () => {
      if (file.value) {
        try {
          const formdata = new FormData();
          formdata.append('file', file.value);
          const endpoint = `/api/v1/transaction/payment/${props.transaction.id}/upload`;
          const response = await axios.post(endpoint, formdata);
          console.log(response.data);
          if (response.data.success) {
            alert('Файл успешно передан')
          }
          else {
            alert('Ошибка передачи файла!')
          }
          onClose();
        } catch (error) {
          console.error(error);
          form.value?.reset();
          file.value = null;
        } finally {
        }
      }
    }

    // const file = ref(null)
    //
    // const fileName = computed(() => file.value?.name);
    // const fileExtension = computed(() => fileName.value?.substr(fileName.value?.lastIndexOf(".") + 1));
    // const fileMimeType = computed(() => file.value?.type);
    //
    // const uploadFile = (event) => {
    //   file.value = event.target.files[0];
    // };
    //
    // const submitFile = async () => {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file.value);
    //   reader.onload = async () => {
    //     const encodedFile = reader.result.split(",")[1];
    //     const data = {
    //       file: encodedFile,
    //       fileName: fileName.value,
    //       fileExtension: fileExtension.value,
    //       fileMimeType: fileMimeType.value,
    //     };
    //     try {
    //       const endpoint = `/api/v1/transaction/payment/${props.transaction.id}/upload`;
    //       const response = await axios.post(endpoint, data);
    //       console.log(response.data);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    // };

    const onClose = () => {
      document.body.classList.remove('noscroll');
      ctx.emit('hide-modal');
      data.changeStatus = false;
    };

    const saveData = (item) => {
      // ctx.emit('save-service-request', item);
      onClose();
    };

    return {onClose, saveData, uploadFile, submitFile, isMobile}

  },
}
</script>

<style scoped>

.table th, .table td {
  padding: 0.2rem 1.4rem
}

.noscroll{
  overflow: hidden !important;
}
.isMobile {
  width: 100vw !important;
}

.pdfmodal {
  position: fixed;
  z-index: 10000;
  background-color: #ffffff;
  top: 0;
  right: 0;
  height: 100vh;
  width: 30vw;
  padding: 30px 30px 30px 20px;
  -webkit-box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  overflow-y: auto;
}
.pdfmodal .close {
  font-size: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 100;
}
.pdf-fade-enter-active, .pdf-fade-leave-active {
  transition: right 0.5s linear;
  right: 0;
}
.pdf-fade-enter, .pdf-fade-leave-to  {
  right: -100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #606f7b;
}
img {
  max-height: 100vh;
}
label.form {
  color: white;
  font-family: Monaco, 'Courier New', Courier, monospace;
  font-weight: bold;
  margin-bottom: 2em;
  display: block;
}
input {
  padding: 0.45em;
  font-size: 1em;
}
.error {
  border: 1px solid red;
  background: pink;
  color: red;
  padding: 0.5em 3em;
  display: inline;
}
a.icon {
  cursor: pointer;
  display: block;
  border: 1px #333 solid;
  background: white;
  color: #333;
  font-weight: bold;
  padding: 0.25em;
  width: 1em;
  height: 1em;
  font-size: 1.5em;
}
.box-shadow {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
.overflow-hidden {
  overflow: hidden;
}
@media print {
  body {
    background-color: transparent;
  }
  #app {
    margin: 0;
    padding: 0;
  }
}
#meters-slider.carousel-control-prev, #meters-slider.carousel-control-next {
  color: #0b2e13;
}
</style>
