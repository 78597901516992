/**
 * url для http и ws серверов 😉
 * @typedef {string} url
 */

/**
 * @constant
 * @type {url}
 */
export const ADDRESS = process.env.VUE_APP_API_ADDRESS
