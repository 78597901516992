import axios from 'axios'
import store from '@/store'

export const USER = () => {
  return new Promise<any>((resolve) => {
    // console.log('get api user');
    axios.post('/api/user')
      .then((response) => {
        // console.log('response.data.success', response.data);
        if (response.data.success) {
          store.dispatch('SET_USER', response.data.user);
        }
        else
          store.dispatch('AUTH_LOGOUT')

        resolve(response.data)
      })
      .catch((error) => {
        console.log('error ', error);
        store.dispatch('AUTH_LOGOUT')
      })
  })
}
