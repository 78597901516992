export default function isDispatcher ({ next, store }){
  // console.log('is middleware type dispatcher ', store.getters.user_type);

    if (store.getters.roles.indexOf(3) !== -1) {
      return next()
    }
    else {
      return next('Dashboard')
    }
}
