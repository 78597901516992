import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cilAirplay,
  cibAdobeAcrobatReader,
  cilImage,
  cilMove,
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCart,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDisabled,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilInfo,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilCut,
  cilDelete,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilClearAll,
  cilTask,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilCompress
} from '@coreui/icons'
import {cilRecycle} from "@coreui/icons/js/free";
import {cilClosedCaptioning} from "@coreui/icons/js/free/cil-closed-captioning";

// @ts-ignore
export const iconsSets = Object.assign(
    {},
    {
      cilClosedCaptioning,
      cilArrowRight,
      cilBan,
      cilBasket,
      cilBell,
      cilCalculator,
      cilCart,
      cilPencil,
      cilCalendar,
      cilCloudDownload,
      cilChartPie,
      cilCheck,
      cilChevronBottom,
      cilChevronTop,
      cilCheckCircle,
      cilCommentSquare,
      cilCursor,
      cilDrop,
      cilInfo,
      cilRecycle,
      cilDollar,
      cilEnvelopeClosed,
      cilEnvelopeOpen,
      cilEuro,
      cilGlobeAlt,
      cilGrid,
      cilFile,
      cilDisabled,
      cilCut,
      cilDelete,
      cilClearAll,
      cilAirplay,
      cilJustifyCenter,
      cilLaptop,
      cilLayers,
      cilLightbulb,
      cilList,
      cilLocationPin,
      cilLockLocked,
      cilMagnifyingGlass,
      cilMoon,
      cilOptions,
      cilPeople,
      cilPuzzle,
      cilSettings,
      cilShieldAlt,
      cilSpeech,
      cilSpeedometer,
      cilStar,
      cilTask,
      cilTrash,
      cilUser,
      cilUserFemale,
      cilUserFollow,
      cilXCircle,
      cilImage,
      cilMove,
      cilCompress
    },
    {
      cifUs,
      cifBr,
      cifIn,
      cifFr,
      cifEs,
      cifPl
    },
    {
      cibFacebook,
      cibTwitter,
      cibLinkedin,
      cibFlickr,
      cibTumblr,
      cibXing,
      cibGithub,
      cibStackoverflow,
      cibYoutube,
      cibDribbble,
      cibInstagram,
      cibPinterest,
      cibVk,
      cibYahoo,
      cibBehance,
      cibReddit,
      cibVimeo,
      cibCcMastercard,
      cibCcVisa,
      cibStripe,
      cibPaypal,
      cibGooglePay,
      cibCcAmex,
      cibAdobeAcrobatReader
    }
  )
